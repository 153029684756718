import React from 'react';
import {Link} from 'react-router-dom';
import {HashLink} from 'react-router-hash-link';
import {useTranslation} from "react-i18next";

const Footer = () => {
    const {t} = useTranslation();
    return (
        <>
            <footer className=" max-h-10">
                <div className="footer max-w-full mx-auto px-4 sm:px-6 bg-gray-100 border-t border-b py-30 max-h-30">

                    {/* Top area: Blocks */}
                    <div className="grid sm:grid-cols-12 gap-5 py-8 md:py-12 border-t border-gray-200 lg:ml-11">

                        {/*/!* 1st block *!/*/}
                        {/*<div className="col-span-4 lg:col-span-4">*/}
                        {/*    <div*/}
                        {/*        className="box-border border-b-4 border-red-900 p-8 bg-black text-gray-100 text-center rounded-lg xl:w-80 mx-auto">*/}
                        {/*        <div><h3 className="font-bold text-4xl mb-4">D&IT</h3></div>*/}
                        {/*        <div className='text-md font-medium text-yellow-400'>*/}
                        {/*            <h5>Coding your dreams </h5><h5>since 1999</h5>*/}
                        {/*            /!*<p>from Turnhout in the land of the surrealBelgium, Turnhout</p>*!/*/}
                        {/*            /!*<p>Ota,</p>*!/*/}
                        {/*            /!*<p>Ogun State.</p>*!/*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        {/* 2nd block */}
                        {/*<div className="col-span-6 md:col-span-6 lg:col-span-1 ml-7 mx-auto">*/}
                            {/*<h6 className="text-[#013289] text-xl font-bold mb-4">LINKS</h6>*/}
                            {/*<ul className="text-md">*/}
                            {/*<li className="mb-2">*/}
                            {/*    <HashLink to="/#about" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">About</HashLink>*/}
                            {/*</li>*/}
                            {/*<li className="mb-2">*/}
                            {/*    <HashLink to="../#services" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Services</HashLink>*/}
                            {/*</li>*/}
                            {/*<li className="mb-2">*/}
                            {/*    <HashLink to="/contact#contact" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Contact</HashLink>*/}
                            {/*</li>                            */}
                            {/*</ul>*/}
                        {/*</div>*/}

                        {/* 3rd block */}
                        {/*<div className="col-span-6 md:col-span-6 lg:col-span-4 mx-auto">*/}
                        {/*    <h6 className="text-red-900 text-xl font-bold mb-4">OUR SERVICES</h6>*/}
                        {/*    <ul className="text-md">*/}
                        {/*        <li className="mb-2 text-white space-x-1">*/}
                        {/*            <HashLink to="../#services"*/}
                        {/*                      className="text-white hover:text-gray-200  transition duration-250 ease-in-out">Services</HashLink>*/}
                        {/*        </li>*/}
                        {/*        /!*<li className="mb-2">*!/*/}
                        {/*        /!*    <HashLink to="../#services" className="text-white hover:text-gray-200  transition duration-250 ease-in-out">Mobile App Development</HashLink>*!/*/}
                        {/*        /!*</li>*!/*/}
                        {/*        /!*<li className="mb-2">*!/*/}
                        {/*        /!*    <HashLink to="../#services" className="text-white  hover:text-gray-200 transition duration-250 ease-in-out">Domain and Hosting</HashLink>*!/*/}
                        {/*        /!*</li>*!/*/}
                        {/*        /!*<li className="mb-2">*!/*/}
                        {/*        /!*    <HashLink to="../#services" className="text-white hover:text-gray-200 h transition duration-250 ease-in-out">General IT Consultations</HashLink>*!/*/}
                        {/*        /!*</li>*!/*/}
                        {/*    </ul>*/}
                        {/*</div>*/}

                        {/* 4th block */}
                        {/*<div className="col-span-12 text-center mx-auto lg:col-span-3 font-bold uppercase text-red-900">*/}
                            {/*<div className="text-xl mb-6">*/}
                            {/*    Social Media Links.*/}
                            {/*</div>*/}

                            {/*        <div className="text-md font-medium mb-6">*/}
                            {/*            Follow us on social media.*/}
                            {/*        </div>*/}
                            {/*<div className="mx-auto text-center mt-2">*/}
                            {/*    <ul className="flex justify-center mb-4 md:mb-0">*/}
                            {/*        <li>*/}
                            {/*            <Link to="#"*/}
                            {/*                  className="flex justify-center items-center text-red-900 hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"*/}
                            {/*                  aria-label="Twitter">*/}
                            {/*                <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32"*/}
                            {/*                     xmlns="http://www.w3.org/2000/svg">*/}
                            {/*                    <path*/}
                            {/*                        d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z"/>*/}
                            {/*                </svg>*/}
                            {/*            </Link>*/}
                            {/*        </li>*/}
                            {/*        <li className="ml-4">*/}
                            {/*            <Link to="#"*/}
                            {/*                  className="flex justify-center items-center text-red-900 hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"*/}
                            {/*                  aria-label="Facebook">*/}
                            {/*                <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32"*/}
                            {/*                     xmlns="http://www.w3.org/2000/svg">*/}
                            {/*                    <path*/}
                            {/*                        d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z"/>*/}
                            {/*                </svg>*/}
                            {/*            </Link>*/}
                            {/*        </li>*/}
                            {/*    </ul>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    </div>

                    <div className="flex flex-wrap items-right md:justify-between justify-center mx-auto px-4">
                        <div className="w-full md:w-11/12 px-4 mx-auto text-center py-2">
                            <div className="text-sm text-gray-200 font-semibold py-1">


                                <ul className="flex justify-left mb-4 md:mb-0 text-gray-400">
                                    <li>
                                        Copyright &copy; {new Date().getFullYear()}{"  "}
                                        <HashLink to="#" className=" hover:text-gray-900">
                                            D&IT
                                        </HashLink>.
                                        {t('footer.rights')}
                                    </li>

                                    {/*<li className="ml-4">*/}
                                    {/*    <Link to="#"*/}
                                    {/*          className="flex justify-center items-center text-red-900 hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"*/}
                                    {/*          aria-label="Facebook">*/}
                                    {/*        <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32"*/}
                                    {/*             xmlns="http://www.w3.org/2000/svg">*/}
                                    {/*            <path*/}
                                    {/*                d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z"/>*/}
                                    {/*        </svg>*/}
                                    {/*    </Link>*/}
                                    {/*</li>*/}

                                    {/*<li className="px-4">*/}
                                    {/*    <Link to="#"*/}
                                    {/*          className="flex justify-center items-center text-red-900 hover:text-gray-500 bg-transparent hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"*/}
                                    {/*          aria-label="Instagram">*/}
                                    {/*        <svg className="w-8 h-8 fill-current" viewBox="0 0 24 24"*/}
                                    {/*             xmlns="http://www.w3.org/2000/svg">*/}
                                    {/*            <path fill-rule="evenodd"*/}
                                    {/*                  d="M8,2 L16,2 C19.3137085,2 22,4.6862915 22,8 L22,16 C22,19.3137085 19.3137085,22 16,22 L8,22 C4.6862915,22 2,19.3137085 2,16 L2,8 C2,4.6862915 4.6862915,2 8,2 Z M8,4 C5.790861,4 4,5.790861 4,8 L4,16 C4,18.209139 5.790861,20 8,20 L16,20 C18.209139,20 20,18.209139 20,16 L20,8 C20,5.790861 18.209139,4 16,4 L8,4 Z M12,17 C9.23857625,17 7,14.7614237 7,12 C7,9.23857625 9.23857625,7 12,7 C14.7614237,7 17,9.23857625 17,12 C17,14.7614237 14.7614237,17 12,17 Z M12,15 C13.6568542,15 15,13.6568542 15,12 C15,10.3431458 13.6568542,9 12,9 C10.3431458,9 9,10.3431458 9,12 C9,13.6568542 10.3431458,15 12,15 Z M17,8 C16.4477153,8 16,7.55228475 16,7 C16,6.44771525 16.4477153,6 17,6 C17.5522847,6 18,6.44771525 18,7 C18,7.55228475 17.5522847,8 17,8 Z"/>*/}
                                    {/*        </svg>*/}
                                    {/*    </Link>*/}
                                    {/*</li>*/}

                                </ul>
                            </div>
                        </div>
                    </div>

                </div>

            </footer>
        </>
    )
}
export default Footer;
