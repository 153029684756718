import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import translation files
import translationEN from './locales/en/translation.json';
import translationNL from './locales/nl/translation.json';

// Define the resources
const resources = {
    en: {
        translation: translationEN
    },
    nl: {
        translation: translationNL
    }
};

// Initialize i18next
i18n
    .use(LanguageDetector) // Detects user language
    .use(initReactI18next) // Passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng: 'en', // Use English if detected language is not available
        debug: false, // Set to true for debugging

        interpolation: {
            escapeValue: false // React already safes from xss
        },
        react: {
            useSuspense: false // Set to false if not using Suspense
        }
    });

export default i18n;
// Compare this snippet from src/components/Portfolio.js:
//                                 </p>
