import React, { useEffect, useState } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';
import Notiflix from 'notiflix';
import emailjs from '@emailjs/browser';
import { useTranslation } from "react-i18next";

const Contact = () => {
    useDocTitle('D&IT | Send us a message');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState([]);
    const { t } = useTranslation();

    const clearErrors = () => {
        setErrors([]);
    };

    const clearInput = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setMessage('');
    };

    useEffect(() => {
        emailjs.init('hj9cK5Vzo1uhcuMQa');
    }, []);

    const sendEmail = (e) => {
        e.preventDefault();

        document.getElementById('submitBtn').disabled = true;
        document.getElementById('submitBtn').innerHTML = 'Loading...';

        emailjs
            .sendForm('service_8724n0k', 'template_w9s72yd', e.target)
            .then(
                () => {
                    clearInput();
                    document.getElementById('submitBtn').disabled = false;
                    document.getElementById('submitBtn').innerHTML = 'Send Message';
                    Notiflix.Report.success('Success', 'Your message has been sent!', 'Okay');
                },
                (error) => {
                    document.getElementById('submitBtn').disabled = false;
                    document.getElementById('submitBtn').innerHTML = 'Send Message';
                    Notiflix.Report.failure('An error occurred', error.text, 'Okay');
                },
            );
    };

    return (
        <div className="flex flex-col min-h-screen">
            <NavBar />

            {/* Main content */}
            <div className="flex-grow flex items-center justify-center pt-80 sm:py-96"> {/* Centering content vertically */}
                <div className="w-full py-8 lg:py-16 flex justify-center items-center">
                    <div className="container mx-auto px-4 lg:px-20" data-aos="zoom-in">
                        <form onSubmit={sendEmail}>
                            <div className="w-full bg-white p-8 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 rounded-2xl shadow-2xl">
                                <h1 className="font-bold text-center lg:text-left text-red-900 uppercase text-4xl">
                                    {t('contact.description')}
                                </h1>
                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                    <div>
                                        <input
                                            name="first_name"
                                            className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                            type="text"
                                            placeholder={t('contact.firstName')}
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                            onKeyUp={clearErrors}
                                        />
                                        {errors.first_name && <p className="text-red-500 text-sm">{errors.first_name}</p>}
                                    </div>

                                    <div>
                                        <input
                                            name="last_name"
                                            className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                            type="text"
                                            placeholder={t('contact.lastName')}
                                            value={lastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                            onKeyUp={clearErrors}
                                        />
                                        {errors.last_name && <p className="text-red-500 text-sm">{errors.last_name}</p>}
                                    </div>

                                    <div>
                                        <input
                                            name="email"
                                            className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                            type="email"
                                            placeholder={t('contact.email')}
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            onKeyUp={clearErrors}
                                        />
                                        {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
                                    </div>

                                    <div>
                                        <input
                                            name="phone_number"
                                            className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                            type="number"
                                            placeholder={t('contact.phone')}
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                            onKeyUp={clearErrors}
                                        />
                                        {errors.phone_number && <p className="text-red-500 text-sm">{errors.phone_number}</p>}
                                    </div>
                                </div>
                                <div className="my-4">
                  <textarea
                      name="message"
                      placeholder={t('contact.message')}
                      className="w-full h-32 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      onKeyUp={clearErrors}
                  ></textarea>
                                    {errors.message && <p className="text-red-500 text-sm">{errors.message}</p>}
                                </div>
                                <button
                                    type="submit"
                                    id="submitBtn"
                                    className="uppercase text-sm font-bold tracking-wide bg-gray-500 hover:bg-red-900 text-gray-100 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline"
                                >
                                    {t('contact.send')}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {/* Footer */}
            <Footer />
        </div>
    );
};

export default Contact;
