import React from 'react';
import tuur from '../images/dnit.png';
import {Link} from 'react-router-dom';
import {HashLink} from "react-router-hash-link";
import {useTranslation} from "react-i18next";
import NavBar from "./Navbar/NavBar";

const Intro = () => {
    const {t} = useTranslation();
    return (
        <>
            <div>
                <NavBar/>
            </div>
            <div className="m-auto max-w-6xl p-2 md:p-12  h-5/6" id='about'>

                <div className="mt-32 md:mt-40  sm:py-16">
                    <div className="flex flex-col lg:flex-row py-8 justify-between lg:text-left items-center"
                         data-aos="fade-up">

                        {/* Left Image */}
                        <div className="flex justify-left lg:w-1/2">
                            <img alt="card img" className="rounded-t" src={tuur}/>
                        </div>

                        {/* Text Content */}
                        <div className="flex flex-col my-4 text-center lg:text-left lg:my-0 lg:w-1/2 px-8"
                             data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="text-3xl text-red-900 font-bold">
                                {t('intro.title')}
                            </h3>

                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>
                                    {t('intro.paragraph1')}
                                </p>
                            </div>

                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>
                                    {t('intro.paragraph2')}
                                </p>
                            </div>

                            <HashLink to="/contact#contact"
                                      className="text-white bg-red-900 hover:bg-red-800 inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group">
                                Contact us
                                <svg className="w-4 h-4 ml-1 group-hover: translate-x-2"
                                     xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd"
                                          d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                          clipRule="evenodd"></path>
                                </svg>
                            </HashLink>
                        </div>

                        {/*/!* Right Image *!/*/}
                        {/*<div className="flex justify-center lg:w-1/4">*/}
                        {/*    <img alt="card img" className="rounded-t" src={tuur}/>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Intro;
