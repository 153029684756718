import React from 'react';
import Clients from '../components/Clients';
import Cta from '../components/Cta';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Intro from '../components/Intro';
import Portfolio from '../components/Portfolio';
import Services from '../components/Services';

const Home = () => {
    return (
        <>
            <div id="body" className="pl-2">
            <Intro/>
            {/*<Hero/>*/}
            <Services/>
            {/*<Portfolio/>*/}
            <Clients/>
            <Cta/>
            <Footer/>
            {/*<img src="dnit-banner-3.png" alt="Logo"/>*/}
            </div>
        </>

    )
}

export default Home;

