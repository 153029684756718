import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';

const NavLinks = ({ closeMenu }) => {
    const { t } = useTranslation();
    return (
        <>
            <HashLink
                className="z-50 px-4 font-extrabold text-gray-500 hover:text-red-900"
                smooth to="/#about"
                onClick={closeMenu} /* Calls closeMenu when clicked */
            >
                {t('navbar.aboutUs')}
            </HashLink>
            <HashLink
                className="px-4 font-extrabold text-gray-500 hover:text-red-900"
                smooth to="/#services"
                onClick={closeMenu} /* Calls closeMenu when clicked */
            >
                {t('navbar.services')}
            </HashLink>
            <HashLink
                className="px-4 font-extrabold text-gray-500 hover:text-red-900"
                to="/#clients"
                onClick={closeMenu} /* Calls closeMenu when clicked */
            >
                {t('navbar.clients')}
            </HashLink>
            <HashLink
                className="px-4 font-extrabold text-gray-500 hover:text-red-900 whitespace-nowrap"
                to="/contact#contact"
                onClick={closeMenu} /* Calls closeMenu when clicked */
            >
                {t('navbar.contact')}
            </HashLink>
        </>
    );
};

export default NavLinks;
