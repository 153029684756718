import React, { useState, useEffect } from 'react';
import NavLinks from '../Navbar/NavLinks';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import { changeLanguage } from 'i18next';

const NavBar = () => {
    const [top, setTop] = useState(!window.scrollY);
    const [isOpen, setisOpen] = useState(false);
    const { t, i18n } = useTranslation();

    function handleClick() {
        setisOpen(!isOpen); // Toggles the menu open/closed
    }

    function closeMenu() {
        setisOpen(false); // Closes the menu
    }

    useEffect(() => {
        const scrollHandler = () => {
            window.pageYOffset > 10 ? setTop(false) : setTop(true);
        };
        window.addEventListener('scroll', scrollHandler);
        return () => window.removeEventListener('scroll', scrollHandler);
    }, [top]);

    return (
        <nav className={`z-50 fixed top-0 w-full transition duration-300 ease-in-out bg-white ${!top && 'bg-white shadow-lg'}`}>

            {/* Flexbox changes for small and large screens */}
            <div className="flex flex-col items-center lg:flex-row lg:justify-between lg:items-center py-1 bg-gwhite">

                {/* Logo */}
                <div className="flex justify-center w-full lg:w-auto">
                    <HashLink smooth to="/#about">
                        <img
                            src={"../../../images/logo6.png"}
                            className="h-40 md:h-60"
                            alt="D&iT Logo"
                        />
                    </HashLink>
                </div>

                {/*<br/>*/}
                {/*<br/>*/}
                {/*<br/>*/}
                {/*<br/>*/}
                {/*<br/>*/}
                {/* Hamburger Button for Small Screens */}
                <div className="flex justify-center w-full mt-4 lg:hidden"> {/* Centered hamburger button */}
                    <button
                        className="p-2 rounded-lg text-white bg-red-900" /* Explicitly set background to red */
                        onClick={handleClick}
                    >
                        <svg className="h-6 w-6 fill-current text-white" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 24 24">
                            {isOpen ? (
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                                />
                            ) : (
                                <path
                                    fillRule="evenodd"
                                    d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2z"
                                />
                            )}
                        </svg>
                    </button>
                </div>

                {/* NavLinks for Large Screens */}
                <div className="hidden lg:flex lg:space-x-4 items-center">

                    <NavLinks/>
                <div>

                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    {/* Language Switcher */}
                    <button
                        onClick={() => changeLanguage('en')}
                        className={`px-2 py-1 rounded ${i18n.language === 'en' ? 'bg-red-900 text-white' : 'bg-white text-gray-700'}`}
                    >
                        EN
                    </button>
                    <button
                        onClick={() => changeLanguage('nl')}
                        className={`px-2 py-1 rounded ${i18n.language === 'nl' ? 'bg-red-900 text-white' : 'bg-white text-gray-700'}`}
                    >
                        NL
                    </button>
                </div>
                   </div>
                                </div>

            {/* Mobile Menu */}
            <div
                className={`fixed z-50 transition-transform duration-300 ease-in-out flex justify-center left-0 w-full h-auto rounded-md p-24 bg-white lg:hidden shadow-xl top-14 ${
                    isOpen ? 'block' : 'hidden'
                }`}
            >
                <div className="flex flex-col space-y-6">
                    <NavLinks closeMenu={closeMenu}/>
                    <br/>
                    <br/>
                </div>
                <div>

                <br/>
                    <br/>
                    <div className="flex space-x-4 justify-center mt-4">
                        <button
                            onClick={() => changeLanguage('en')}
                            className={`px-2 py-1 rounded ${i18n.language === 'en' ? 'bg-red-900 text-white' : 'bg-white text-gray-700'}`}
                        >
                            EN
                        </button>
                        <button
                            onClick={() => changeLanguage('nl')}
                            className={`px-2 py-1 rounded ${i18n.language === 'nl' ? 'bg-red-900 text-white' : 'bg-white text-gray-700'}`}
                        >
                            NL
                        </button>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default NavBar;
